.body-overflow {
    height: 100%;
    overflow: hidden;

    .nav {
        visibility: visible;
        opacity: 1;
        z-index: 999;

        &__toggle {
            span {
                background-color: $white;

                &:first-child {
                    top: 18px;
                    width: 0;
                    left: 50%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 18px;
                    width: 0;
                    left: 50%;
                }
            }
        }
    }
}

.nav {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $primary url("../images/navigation.jpg") center center no-repeat;
    background-size: cover;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: all 0.5s ease-out;
    z-index: 999;

    &__logo {
        width: 150px;
        height: auto;

        @screen md {
            width: 260px;
            height: auto;
        }
    }

    &__toggle {
        width: 30px;
        height: 25px;
        position: relative;
        transition: 0.5s ease-in-out;
        z-index: 11;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background-color: $primary;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transition: 0.25s ease-in-out;

            &:first-child {
                top: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 10px;
            }

            &:nth-child(4) {
                top: 20px;
            }
        }
    }

    &__lang{
        color: $primary;
    }

    &--white {
        .nav__toggle {
            span {
                background-color: $white;
            }
        }
        .nav__lang{
            color: $white;
        }
    }

    ul {
        list-style-type: none;
        margin-top: 2rem;

        li {
            text-align: right;

            &:not(:first-of-type) {
                margin-top: 1rem;
            }

            &:before {
                content: none;
            }

            a {
                color: $white;
                text-align: right;
                text-decoration: none;
                font-size: 1.2rem;
                @screen md{
                    font-size: 2rem;
                }
                padding: 0.2rem;
                font-family: $title-font-family;
                text-transform: uppercase;
                font-weight: bold;
                border-bottom: 2px solid transparent;
                padding-bottom: 0.4rem;
                transition: all 0.3s ease-out;

                color: $secondary;
                padding: 0.2rem;

                &:hover, &.active {
                    background-color: $secondary;
                    color: $dark;
                }

                &.nav--lang{
                    color: $white;
                    @apply text-xs;
                    @apply font-sans;
                    &:hover, &.active {
                        background-color: $white;
                        color: $dark;
                    }
                }

                &.nav--white {
                    color: $white;

                    &:hover, &.active {
                        background-color: $white;
                        color: $dark;
                    }
                }

                &.nav--primary {
                    color: $primary;

                    &:hover, &.active {
                        background-color: $primary;
                        color: $white;
                    }
                }


            }
        }
    }


}