.footer {
    &__contact{
        position: relative; 
        &:after{
            content: '';
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNy40IDMxLjMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI3LjQgMzEuMzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNGQkRBNzE7fQo8L3N0eWxlPgo8cGF0aCBpZD0iUGF0aF82MjQiIGNsYXNzPSJzdDAiIGQ9Ik0wLDMxLjNWMGgyNy40TDAsMzEuM3oiLz4KPC9zdmc+Cg==') center center no-repeat;
            width: 28px;
            height: 32px;
            display: block;
            position: absolute;
            bottom: -30px;
            left: 20px;
        }
    }
    a{
        &.active{
            color: $secondary;
        }
    }
}