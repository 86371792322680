ul {

    &.list-bullet {
        li {
            position: relative;
            padding-left: 1.5rem;

            &:before {
                content: '';
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: $secondary;
                display: block;
                position: absolute;
                top: 6px;
                left: 0;
            }

            @screen xl{
                top: 12px;

            }
        }
    }

    &.list--spacing {
        li {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }

    &.list-bullet--primary {
        li {
            &:before {
                background-color: $primary;
            }
        }
    }

    &.list-bullet--secondary {
        li {
            color: $white;

            &:before {
                background-color: $secondary;
            }
        }
    }
}