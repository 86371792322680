.image {
    &__bg {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;




        &-image {
            order: 2;

            @screen xl {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }

          
        }

        .container {
            position: relative;
        }
    }

    &__button {
        @screen xl {
            position: absolute;
            top: 0;
            right: 0.9rem;
        }
    }
}