/*
 * MIXINS
 */


// FONT-FACE
@mixin fontFace($family,$src,$weight: normal,$style: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$src}.woff2') format('woff2');
        font-style: $style;
        font-weight: $weight;
        font-display: swap;
        font-display: normal;
    }
}