.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;

    &--large { 
        @screen xl {
            height: 100vh;
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 8; 
    }


    &__image--large {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        &:hover{
            cursor: zoom-in; 
        }
    }
 
    .container {
        position: absolute;
    }

    &--accent {
        &:after {
            content: '';
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOTIwLjUgNjc4LjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE5MjAuNSA2NzguODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMwRkI4OUE7fQo8L3N0eWxlPgo8cGF0aCBpZD0iU3VidHJhY3Rpb25fMjRfMDAwMDAwMjQ3MDgzMTE2OTY0NzQ3NzA5NDAwMDAwMDI0MDIzMzA4MzI1Mzc1NTUwODVfIiBjbGFzcz0ic3QwIiBkPSJNMTkyMCw2NzguOEgwVjBsODg4LjQsNjc3LjgKCUwxOTIwLjUsMjQ3Ii8+Cjwvc3ZnPgo=') center bottom no-repeat;
            background-size: cover;
            width: 100%;
            position: absolute;
            bottom: -1px;
            left: 0;
            display: block;
            z-index: 2;
            height: 100%;
        }
    }


}