/*
 *  STYLES
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "vendor/aos";

@import "tools/tools.mixins";

@import "settings";

html,
body {
    font-family: $base-font-family;
    background-color: $body_color;
    color: $body_text;
    overflow-x: hidden;
    font-size: 18px;
    @screen xl{
        font-size: 24px;
    }
    scroll-behavior: smooth;
    font-weight: 300;
}

h1 {
    font-weight: bold;
    font-family: $title-font-family;
}

h2 {
    @apply leading-normal;
    font-size: 3rem;
    font-weight: bold;
    @apply mb-4;
    font-family: $title-font-family;
}

h3 {
    @apply mb-4;
    font-weight: bold;
    font-family: $title-font-family;

    &:not(:first-of-type) {
        margin-top: 1.5rem;
    }
}

h4 {
    @apply mb-4;
    font-family: $title-font-family;
    font-weight: bold;
}

h5 {
    @apply mb-4;
    text-transform: none;
}

p,
ul,
ol {
    margin-bottom: 1rem;

    a {
        @apply underline;
        color: $primary;

        &:hover {
            @apply no-underline;
        }
    }
}

a{
    strong{
        font-weight: bold;
    }
}

.span-white{
    span{
        color: $white;
    }
}

.span-dark{
    span{
        color: $dark;
    }
}

.span-primary{
    span{
        color: $primary;
    }
}

.span-secondary{
    span{
        color: $secondary;
    }
}

/**
** COMPONENTS
*/


@import "components/components.footer";
@import "components/components.hero";
@import "components/components.image";
@import "components/components.lists";
@import "components/components.navigation";