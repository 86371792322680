
// FONTS
@include fontFace('Aleo','../fonts/aleo/Aleo-Regular', 400);
@include fontFace('Aleo','../fonts/aleo/Alea-Bold', 700);

@include fontFace('Lato','../fonts/lato/lato-regular', 400);
@include fontFace('Lato','../fonts/lato/lato-700', 700);

$base-font-family: 'Lato', sans-serif;
$title-font-family: 'Aleo', serif;

// COLORS
$dark: theme('colors.black');
$light: theme('colors.light');
$primary: theme('colors.primary');
$secondary: theme('colors.secondary');

$dark_opacity: theme('colors.dark_opacity');
$white: theme('colors.white');

$white_opacity: '#88898C';

$error: #e70638;
 
$colors: (
    dark: theme('colors.dark'),
    light: theme('colors.light'),
    primary: theme('colors.primary'),
    secondary: theme('colors.secondary'),
);

// SECTION COLORS
$body_color: $white;
$body_text: $dark;

$nav_color: $white;
$nav_text: $dark;
$nav_text_active: $primary;

$footer_color: $primary;
$footer_text: $white;